<template>
    <div class="matchmaker-wine-like relative h-full">
        <h2 v-if="data.question" ref="heading" class="text-white text-center">{{ translations[data.question] }}</h2>

        <div class="flex gap-x-7 justify-center mt-3">
            <bottle-slider
                :label-max="translations.step3.powerMax"
                :label-min="translations.step3.powerMin"
                :reverse="true"
                :bottle-type="data['bottle-type']"
                v-model="power"
                class="matchmaker-wine-like__bottle opacity-0"
            ></bottle-slider>
            <bottle-slider
                v-if="data['second-param'] === 'sugar'"
                :label-max="translations.step3.sugarMax"
                :label-min="translations.step3.sugarMin"
                :bottle-type="data['bottle-type']"
                v-model="sugar"
                class="matchmaker-wine-like__bottle opacity-0"
            ></bottle-slider>
            <bottle-slider
                v-if="data['second-param'] === 'taste'"
                :label-max="translations.step3.tasteMax"
                :label-min="translations.step3.tasteMin"
                :bottle-type="data['bottle-type']"
                v-model="taste"
                class="matchmaker-wine-like__bottle opacity-0"
            ></bottle-slider>
        </div>
        <button class="btn btn--M btn--plain-white btn--arrow absolute -bottom-0 right-0 transform translate-y-full md:bottom-8 md:left-1/2 md:right-inherit md:-translate-x-1/2 z-10" @click="exitStep">{{ translations.common.seeResults }}</button>
    </div>
</template>

<script>
    import BottleSlider from "@/app/components/BottleSlider.vue";
    import gsap from "@lahautesociete/gsap";

    export default {
        name: 'MatchmakerWineLike',

        components: { BottleSlider },

        props: {
            data: { type: Object, default: () => {} },
            translations: { type: Object, default: () => {} }
        },

        data: () => ({
            power: 1,
            sugar: 1,
            taste: 1
        }),

        mounted() {
            console.log('mounted enter');
            this.enter();
        },

        methods: {
            enter() {
                this.tl = gsap.timeline();

                this.tl.fromTo(this.$refs.heading, {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.tl.fromTo(document.querySelectorAll('.matchmaker-wine-like__bottle'), {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    stagger: 0.2,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            },

            exitStep() {
                this.tl = gsap.timeline({
                    onComplete: () => {
                        this.$emit('end-step', {
                            values: {
                                power: this.power,
                                sugar: this.data['second-param'] === 'sugar' ? this.sugar : 0,
                                taste: this.data['second-param'] === 'taste' ? this.taste : 0
                            },
                            go: this.data.go
                        });
                    }
                });

                this.tl.fromTo(this.$refs.heading, {
                    y: 0,
                    opacity: 1
                }, {
                    y: -20,
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.tl.fromTo(document.querySelectorAll('.matchmaker-wine-like__bottle'), {
                    y: 0,
                    opacity: 1
                }, {
                    y: -20,
                    stagger: 0.2,
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            }
        }
    }
</script>
