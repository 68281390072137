import "./assets/styles/main.scss";

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import Matchmaker from "@/app/components/Matchmaker.vue";
import MatchmakerIntro from "@/app/components/MatchmakerIntro.vue";
import MatchmakerResults from "@/app/components/MatchmakerResults.vue";
import ChoiceCard from "@/app/components/ChoiceCard.vue";
import MatchmakerSingleChoiceStep from "@/app/components/MatchmakerSingleChoiceStep.vue";
import ProgressBar from "@/app/components/ProgressBar.vue";
import MatchmakerBonus from "@/app/components/MatchmakerBonus.vue";
import BottleSlider from "@/app/components/BottleSlider.vue";
import LegalPopin from "@/app/components/LegalPopin.vue";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        Matchmaker,
        MatchmakerIntro,
        MatchmakerResults,
        ChoiceCard,
        MatchmakerSingleChoiceStep,
        ProgressBar,
        MatchmakerBonus,
        BottleSlider,
        LegalPopin
    }
}).mount('#app');
