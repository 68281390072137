<template>
    <div class="single-choice-step">
        <h2 v-if="data.question" ref="heading" class="text-white text-center opacity-0">{{ translations[data.question] }}</h2>

        <div class="grid grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1 gap-3 mt-3 md:mt-7">
            <div
                ref="cards"
                v-for="(answer, index) in data.answers"
                :key="`answer-${index}`"
                class="single-choice-step__answer opacity-0"
            >
                <ChoiceCard
                    :data="answer"
                    :translations="translations"
                    :align="data['card-type']"
                    @button-clicked="exitStep"
                    class="w-full h-[20vh]"
                ></ChoiceCard>
            </div>
        </div>
    </div>
</template>

<script>
    import gsap from '@lahautesociete/gsap';
    import ChoiceCard from "@/app/components/ChoiceCard.vue";

    export default {
        name: 'MatchmakerSingleChoiceStep',

        components: { ChoiceCard },

        props: {
            data: { type: Object, default: () => {} },
            translations: { type: Object, default: () => {} }
        },

        mounted() {
            this.enter();
        },

        updated() {
            this.enter();
        },

        methods: {
            enter() {
                this.tl = gsap.timeline();

                this.tl.fromTo(this.$refs.heading, {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.tl.fromTo(this.$refs.cards, {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    stagger: 0.2,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            },

            exitStep(choice) {
                this.tl = gsap.timeline({
                    delay: 0.5,
                    onComplete: () => {
                        this.$emit('end-step', choice);
                    }
                });

                this.tl.to(this.$refs.heading, {
                    y: -20,
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.tl.to(this.$refs.cards, {
                    y: -20,
                    stagger: 0.2,
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            }
        }
    }
</script>

<style lang="scss">
    .single-choice-step {
        &__answer {
            flex: 0 0 50%;
        }
    }
</style>
