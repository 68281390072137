<template>
    <div>
        toto
    </div>
</template>

<script>
    export default {
        name: 'MatchmakerIntro'
    }
</script>
