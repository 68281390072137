<template>
    <button
        v-if="data && data.label"
        :class="{'flex-col': align === 'COL', 'flex-row-reverse': align === 'ROW', 'border-cyan text-cyan': selected, 'border-white text-blue': !selected}"
        class="choice-card relative flex justify-center items-center border-3 bg-white rounded-2xl p-4 body transition-all duration-300 aspect-square md:w-full md:h-full max-w-[237px] max-h-[237px]"
        @click="btnClicked"
    >
        <span :class="{'mb-1': align === 'COL', 'ml-2': align === 'ROW'}" class="relative flex justify-center items-center w-10 md:w-12 md:h-12">
            <img
                v-if="data.icon"
                :src="require(`@/static/images/icons/${data.icon}.svg`)"
                :alt="data.label ?? ''"
                :class="{'opacity-0': selected && align === 'COL'}"
                class="transition-all duration-300 w-full"
            />
            <img
                v-if="data.icon && align === 'COL'"
                :src="require(`@/static/images/icons/${data.icon}-blue.svg`)"
                :alt="data.label ?? ''"
                :class="{'opacity-100': selected, 'opacity-0': !selected}"
                class="absolute inset-0 transition-all duration-300 w-full"
            />
        </span>
        <span :class="{'w-16': align === 'ROW'}">{{ translations[data.label] }}</span>
        <span ref="selectIcon" class="choice-card__check flex justify-center items-center w-8 h-8 bg-white rounded-full absolute -top-2.5 -right-1.5 transform scale-0">
            <img src="~@/static/images/icons/check.svg" alt="Check" />
        </span>
    </button>
</template>

<script>
import gsap from "@lahautesociete/gsap";

export default {
    name: 'ChoiceCard',

    props: {
        data: { type: Object, default: () => {} },
        translations: { type: Object, default: () => {} },
        align: { type: String, default: 'COL' }
    },

    data: () => ({
        selected: false
    }),

    watch: {
        selected() {
            if (this.selected) {
                // Show the select icon
                gsap.to(this.$refs.selectIcon, {
                    scale: 1,
                    duration: 0.7,
                    ease: 'back.out'
                });
            }
        },

        data() {
            // If the data change, it means, that the same card is used for a different question so
            // Deselect the card
            this.selected = false;

            // Hide the select icon
            gsap.set(this.$refs.selectIcon, {
                scale: 0,
            });
        }
    },

    methods: {
        btnClicked() {
            this.selected = true;

            this.$emit('button-clicked', this.data)
        }
    }
}
</script>

<style lang="scss">
    .choice-card {
        &__check {
            box-shadow: 0px 2.458px 3.686px 0px rgba(0, 0, 0, 0.30);
        }
    }
</style>
