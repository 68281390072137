<template>
    <div class="matchmaker relative pb-px w-screen overflow-hidden">
        <div ref="container" class="h-svh">
            <div ref="content" class="matchmaker__content h-full flex flex-col w-full overflow-x-hidden overflow-y-scroll">
                <div class="relative w-full max-w-[450px] md:max-w-[984px] mx-auto px-6 h-full flex flex-col">
                    <div ref="backBtnDesk" class="hidden absolute top-24 left-8 opacity-0">
                        <button class="btn btn--S btn--ghost-white btn--back" @click="back">
                            {{ translations.common.back }}
                        </button>
                    </div>
                    <header ref="header" class="matchmaker__header relative h-svh flex flex-col justify-center items-center text-center min-h-[188px] md:max-w-[645px] mx-auto">
                        <div ref="headerPlacer" class="h-0"></div>
                        <img ref="logoAldi" src="~@/static/images/logo-aldi.svg" alt="logo ALDI" class="matchmaker__aldi-logo absolute left-1/2 top-6 w-10 transform -translate-x-1/2 md:relative md:left-0 md:top-0 md:w-16 md:translate-x-0 md:mb-8" />
                        <div ref="imgIntro" class="pb-6 md:pb-8">
                            <img src="~@/static/images/intro.svg" alt="logo intro" />
                        </div>
                        <img ref="logoExp" src="~@/static/images/logo-exp.svg" alt="L'assistant sommelier" class="matchmaker__exp-logo md:w-[290px]"/>
                        <div ref="part1" class="flex flex-col justify-center items-center overflow-hidden pb-[50px]">
                            <p ref="introText" class="body text-white mt-6 md:mt-8">{{ translations.intro.text }}</p>
                            <img ref="arrowScroll" src="~@/static/images/icons/scrolldown.svg" alt="Scroll down" class="mt-6 md:mt-8 cursor-pointer" @click="introScrolled"/>
                        </div>
                        <div ref="part2" class="flex flex-col justify-center items-center overflow-hidden h-0">
                            <div ref="part2Content" class="flex flex-col items-center">
                                <p ref="introText2" class="body text-white opacity-0 mt-6" v-html="translations.intro.text2"></p>
                                <img ref="introImg" src="~@/static/images/portrait-sommelier.png" alt="Image d'accueil" class="matchmaker__portrait-img opacity-0 mt-6" />
                                <button ref="introBtn" class="relative btn btn--plain-white btn--M mt-4 opacity-0 z-10" @click="endIntro">{{ translations.intro.startButton }}</button>
                            </div>
                        </div>
                        <div ref="progress" class="matchmaker__progress-bar w-full flex justify-center items-center h-0 opacity-0 overflow-hidden flex-shrink-0">
                            <ProgressBar :max="maxStep" :current="currentStepCount"></ProgressBar>
                        </div>
                    </header>
                    <div v-if="currentStepId !== 'intro'" ref="currentStep" class="matchmaker__current-step flex-grow mt-5 pb-28">
                        <component
                            v-if="currentStep && currentStep.type"
                            :is="currentStep.type"
                            :data="currentStep"
                            :products="matchProducts"
                            :translations="translations"
                            @end-step="updateStep"
                            @restart="restart"
                        ></component>
                    </div>
                    <div
                        ref="bottomUtils"
                        :class="{'justify-start': currentStepCount === maxStep, 'justify-center': currentStepCount !== maxStep}"
                        class="absolute bottom-0 left-4 right-4 flex items-start h-[112px] mx-4 transition-all duration-500 overflow-hidden"
                    >
                        <div ref="backBtnMobile" class="items-center h-[50px] hidden opacity-0">
                            <button class="btn-text btn-text--back " @click="back">
                                {{ translations.common.back }}
                            </button>
                        </div>
                        <div class="matchmaker__legal-warning flex flex-col justify-center items-center absolute bottom-1 md:bottom-4 left-0 right-0">
                            <img src="~@/static/images/icons/abus.png" alt="Attention abus"/>
                            <p class="body-4 font-bold text-white mt-1">L'abus d'alcool est dangereux pour la santé</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MatchmakerBonus v-if="currentStepId === 'results'"  :translations="translations" :recipe="{
            url: this.matchProducts[this.currentProductIndex].recipeLink,
            heading: this.matchProducts[this.currentProductIndex].recipeHeading
        }"></MatchmakerBonus>
        <LegalPopin :translations="translations"></LegalPopin>
    </div>
</template>

<script>
    import gsap from '@lahautesociete/gsap';
    import MatchmakerIntro from "@/app/components/MatchmakerIntro.vue";
    import MatchmakerResults from "@/app/components/MatchmakerResults.vue";
    import ChoiceCard from "@/app/components/ChoiceCard.vue";
    import MatchmakerSingleChoiceStep from "@/app/components/MatchmakerSingleChoiceStep.vue";
    import MatchmakerWineLike from "@/app/components/MatchmakerWineLike.vue";
    import MatchmakerBonus from "@/app/components/MatchmakerBonus.vue";
    import ProgressBar from "@/app/components/ProgressBar.vue";
    import LegalPopin from "@/app/components/LegalPopin.vue";
    import BottleSlider from "@/app/components/BottleSlider.vue";
    import tree from '../steps.json';

    export default {
        name: 'Matchmaker',

        components: { MatchmakerIntro, MatchmakerResults, ChoiceCard, MatchmakerSingleChoiceStep, MatchmakerWineLike, MatchmakerBonus, ProgressBar, LegalPopin, BottleSlider },

        props: {
            translations: { type: Object, default: () => {}, required: true },
            products: { type: Array, default: () => [], required: true }
        },

        data: () => ({
            choices: [],
            history: [],
            currentStepId: 'intro',
            currentStepCount: 1,
            maxStep: 3,
            currentProductIndex: 0,
            breakpoint: 768,
            device: 'mobile'
        }),

        computed: {
            currentStep() {
                return tree.steps.filter(step => step.id === this.currentStepId)[0];
            },

            matchProducts() {
                const products = JSON.parse(JSON.stringify(this.products));
                let result = [];

                // If all the steps are not all done
                if (this.choices.length === 1) {
                    const shuffled = products.sort(() => 0.5 - Math.random()); // Shuffle the array
                    result = shuffled.slice(0, 3);
                } else {

                    result = products.filter((product) => {
                        let test = 0;

                        this.choices.forEach((choice) => {
                            console.log('choice', choice)
                            // If choices has several values
                            if (choice.values) {
                                // If it has sugar
                                if (choice.values.sugar) {
                                    test = product.power === choice.values.power && product.sugar === choice.values.sugar ? test + 1 : test;
                                } else if (choice.values.taste) { // if it has taste
                                    test = product.power === choice.values.power && product.taste === choice.values.taste ? test + 1 : test;
                                }
                            } else { // Only one value
                                // Case occasion
                                if (choice.map === 'occasions') {
                                    test = product.occasions.filter(occasion => occasion.value === choice.value).length > 0 ? test + 1 : test;
                                } else if (choice.map === 'color') {
                                    test = product.color.value === choice.value ? test + 1 : test;
                                }
                            }
                        });

                        return test === this.choices.length;
                    });

                    // If we have no corresponding products
                    if (result.length === 0) {
                        // Get the product from the colors
                        const associatedProducts = products.filter((product) => {
                            return product.color.value === this.choices[1].value
                        });

                        // Get a random number to get random product in color
                        const randNumber = Math.floor(Math.random() * (associatedProducts.length - 1));

                        // Return the random product
                        result = [associatedProducts[randNumber]];
                    }
                }

                return result;
            }
        },

        mounted() {
            // Declare loops
            this.setupLoops();

            // Begin with the introduction
            this.showIntro();

            this.resizeHandler();

            window.addEventListener('resize', this.resizeHandler);
        },

        methods: {
            updateStep(choice) {
                console.log('choice', choice);
                // Save current step into history
                this.history.push(this.currentStep);

                // Add the choice
                this.choices.push(choice);

                if (choice.go !== 'results') {
                    // Increase step count
                    this.currentStepCount++;
                } else {
                    this.goToResults();
                }

                // Update the current id with the new one
                this.currentStepId = choice.go;
            },

            back() {
                this.history = JSON.parse(JSON.stringify(this.history));

                // If we have something in the history
                if (this.history.length > 0) {
                    // Remove last choice
                    this.choices = this.choices.slice(0, -1);

                    // Update the current step id with the last one in history
                    this.currentStepId = this.history[this.history.length - 1].id;

                    // Remove last from history
                    this.history = this.history.slice(0, -1);

                    // Count of step -1
                    this.currentStepCount = Math.max(this.history.length + 1, 0);

                    this.placeOnBack();
                } else {
                    // Reset
                    this.reset();

                    // Reset choices
                    this.choices = [];

                    // Reset current step id
                    this.currentStepId = 'intro';
                }
            },

            restart() {
                // Reset
                this.choices = [];
                this.history = [];
                this.currentStepCount = 1;

                this.currentStepId = tree.steps[0].go;

                // Update the container
                gsap.to(this.$refs.container, {
                    height: window.innerHeight,
                    duration: 0.6,
                    ease: 'power1.out'
                }, 0);

                gsap.to(this.$refs.bottomUtils, {
                    height: 112,
                    duration: 0.3,
                    ease: 'power2.out'
                }, 0);

                gsap.to(this.$refs.progress, {
                    height: 22,
                    marginTop: window.innerWidth < 768 ? 24 : (window.innerHeight < 700) ? 50 : 112,
                    duration: 0.3,
                    ease: 'power2.out'
                }, 0);

                gsap.to(this.$refs.header, {
                    height: window.innerWidth < 768 ? Math.min(window.innerHeight*30/100, 250) : window.innerHeight < 700 ? 267 : 337,
                    duration: 0.3,
                    ease: 'power2.out'
                }, 0);

                // Update the content
                gsap.to(this.$refs.content, {
                    paddingBottom: 0,
                    duration: 0.6,
                    ease: 'power1.out'
                }, 0);

                gsap.to(this.$refs.currentStep, {
                    paddingBottom: 112,
                    duration: 0.6,
                    ease: 'power1.out'
                }, 0)

                gsap.to(this.$refs.headerPlacer, {
                    height: Math.min(window.innerHeight*30/100, 250) - this.$refs.logoExp.clientHeight - 22 - 24,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 0);
            },

            reset() {
                this.resetTL = gsap.timeline({
                    onComplete: () => {
                        // this.showIntro();
                    }
                });

                this.resetTL.to(this.$refs.header, {
                    opacity: 0,
                    height: window.innerHeight,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.resetTL.to(this.$refs.headerPlacer, {
                    height: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                }, 0);

                this.resetTL.set(this.$refs.part1, {
                    opacity: 1,
                    height: 'auto'
                });

                this.resetTL.set(this.$refs.imgIntro, {
                    opacity: 1,
                    paddingBottom: window.innerWidth > this.breakpoint ? 32 : 24,
                    height: 'auto'
                });

                this.resetTL.set(this.$refs.progress, {
                    display: 'none',
                    opacity: 0
                });

                this.resetTL.to(this.$refs.header, {
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                const backBtn = window.innerWidth < 768 ? this.$refs.backBtnMobile : this.$refs.backBtnDesk;
                this.resetTL.set(backBtn, {
                    opacity: 0,
                    display: 'none',
                });

                this.resetTL.set(this.$refs.bottomUtils, {
                    height: 112,
                });

                // Show the content
                this.resetTL.fromTo([this.$refs.introText, this.$refs.arrowScroll], {
                    y: -20,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    stagger: 0.3,
                    duration: 0.8,
                    ease: 'power2.out',
                    onComplete: () => {
                        window.addEventListener('wheel', this.introScrolled);
                        window.addEventListener('scroll', this.introScrolled);
                    }
                });
            },

            goToResults() {
                // Hide the unecessary components
                gsap.to([this.$refs.progress, this.$refs.bottomUtils], {
                    height: 0,
                    marginTop: 0,
                    duration: 0.3,
                    ease: 'power2.out'
                });

                gsap.to(this.$refs.header, {
                    height: this.$refs.header.clientHeight - 22 - 24,
                    duration: 0.3,
                    ease: 'power2.out'
                });

                // Update the container
                gsap.to(this.$refs.container, {
                    padding: 0,
                    height: 'auto',
                    duration: 0.6,
                    ease: 'power1.out'
                });

                // Update the content
                gsap.to(this.$refs.content, {
                    paddingBottom: 0,
                    duration: 0.6,
                    ease: 'power1.out'
                });

                gsap.to(this.$refs.currentStep, {
                    paddingBottom: 48,
                    duration: 0.6,
                    ease: 'power1.out'
                })
            },

            placeOnBack() {
                // Hide the unecessary components
                gsap.to(this.$refs.bottomUtils, {
                    height: 112,
                    duration: 0.3,
                    ease: 'power2.out'
                });

                gsap.to(this.$refs.header, {
                    height: window.innerWidth < 768 ? Math.min(window.innerHeight*30/100, 250) : window.innerHeight < 700 ? 267 : 337,
                    duration: 0.6,
                    ease: 'power2.out',
                });

                // Update the container
                gsap.to(this.$refs.container, {
                    height: window.innerHeight,
                    duration: 0.6,
                    ease: 'power1.out'
                });

                // Update the content
                // gsap.to(this.$refs.content, {
                //     paddingBottom: 0,
                //     duration: 0.6,
                //     ease: 'power1.out'
                // });

                gsap.to(this.$refs.currentStep, {
                    paddingBottom: 112,
                    duration: 0.6,
                    ease: 'power1.out'
                })

                gsap.to(this.$refs.headerPlacer, {
                    height: Math.min(window.innerHeight*30/100, 250) - this.$refs.logoExp.clientHeight - 22 - 24,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 0.6);


                gsap.to(this.$refs.progress, {
                    height: 22,
                    marginTop: window.innerWidth < 768 ? 24 : (window.innerHeight < 700) ? 50 : 112,
                    opacity: 1,
                    duration: 0.6
                }, 0.6);


                const backBtn = window.innerWidth < 768 ? this.$refs.backBtnMobile : this.$refs.backBtnDesk;
                gsap.to(backBtn, {
                    opacity: 1,
                    display: 'inline-flex',
                    duration: 0.6,
                    ease: 'power2.out'
                }, 1.2);
            },

            // Create timelines for the loops
            setupLoops() {
                // Loop for the arrow
                this.arrowTL = new gsap.timeline({ repeat: -1, repeatDelay: 4, delay: 4 });

                this.arrowTL.to(this.$refs.arrowScroll, {
                    y: 50,
                    duration: 0.8,
                    ease: 'power2.out'
                });

                this.arrowTL.to(this.$refs.arrowScroll, {
                    y: 0,
                    duration: 1.3,
                    ease: 'power1.in'
                });

                this.arrowTL.pause();
            },

            // Show the part 1 of the intro
            showIntro() {
                // Init intro tl
                this.introTL = gsap.timeline({
                    onComplete: () => {
                        this.arrowTL.play();
                    },
                    delay: 0.5
                });

                // Show the content
                this.introTL.fromTo([this.$refs.imgIntro, this.$refs.logoExp, this.$refs.introText, this.$refs.arrowScroll], {
                    y: -20,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    stagger: 0.3,
                    duration: 0.8,
                    ease: 'power2.out',
                    onComplete: () => {
                        window.addEventListener('wheel', this.introScrolled);
                        window.addEventListener('scroll', this.introScrolled);
                    }
                });
            },

            // When the intro is scrolled => intro pt.2
            introScrolled() {
                window.removeEventListener('wheel', this.introScrolled);
                window.removeEventListener('scroll', this.introScrolled);
                if (this.arrowTL) this.arrowTL.pause();

                this.part2TL = gsap.timeline();

                if (window.innerWidth < 768) {
                    // Move logo aldi
                    this.part2TL.fromTo(this.$refs.logoAldi, {
                        left: '50%',
                        x: '-50%',
                    },{
                        left: 24,
                        x: 0,
                        opacity: 1,
                        duration: 0.6,
                        ease: 'power3.out'
                    }, 0);
                }

                // Hide text and arrow part1
                this.part2TL.to([this.$refs.introText, this.$refs.arrowScroll, this.$refs.imgIntro], {
                    opacity: 0,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 0);

                // Reduce height of part1
                this.part2TL.fromTo([this.$refs.part1, this.$refs.imgIntro], {
                    height: 'auto',
                }, {
                    height: 0,
                    paddingBottom: 0,
                    duration: (this.$refs.part1.clientHeight + this.$refs.imgIntro.clientHeight) * 0.0012
                }, 0.6);

                // Rise height of part2
                this.part2TL.fromTo(this.$refs.part2, {
                    height: 0
                },{
                    height: this.$refs.part2Content.clientHeight,
                    duration: this.$refs.part2Content.clientHeight * 0.0012,
                }, 0.6);

                // // Show part 2 text and co
                this.part2TL.fromTo([this.$refs.introText2, this.$refs.introImg, this.$refs.introBtn], {
                    y: 30,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.4,
                    stagger: 0.1,
                    ease: 'power2.out'
                }, 1.2);
            },

            // Hide entirely the intro
            endIntro() {
                this.endTL = gsap.timeline({ onComplete: () => {
                    // On the end, we go to the next step
                    this.currentStepId = this.currentStep.go;
                }});

                // Hide part 2 text and co
                this.endTL.to([this.$refs.introText2, this.$refs.introImg, this.$refs.introBtn],{
                    opacity: 0,
                    duration: 0.6,
                    ease: 'power2.out'
                });

                this.endTL.to(this.$refs.header, {
                    height: window.innerWidth < 768 ? Math.min(window.innerHeight*30/100, 250) : window.innerHeight < 700 ? 267 : 337,
                    duration: 0.6,
                    ease: 'power2.out',
                }, 0.6);

                // Reduce height of part2
                this.endTL.to(this.$refs.part2, {
                    height: 0,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 0.6);

                this.endTL.to(this.$refs.headerPlacer, {
                    height: Math.min(window.innerHeight*30/100, 250) - this.$refs.logoExp.clientHeight - 22 - 24,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 0.6);


                this.endTL.to(this.$refs.progress, {
                    display: 'flex',
                    height: 22,
                    duration: 0.6
                }, 0.6);


                this.endTL.to(this.$refs.progress, {
                    opacity: 1,
                    duration: 0.6,
                    ease: 'power2.out'
                }, 1.2);

                const backBtn = window.innerWidth < 768 ? this.$refs.backBtnMobile : this.$refs.backBtnDesk;
                this.endTL.to(backBtn, {
                    opacity: 1,
                    display: 'inline-flex',
                    duration: 0.6,
                    ease: 'power2.out'
                }, 1.2);
            },

            resizeHandler() {
                this.device = (window.innerWidth < this.breakpoint) ? 'mobile' : 'desktop';
            }
        }
    }
</script>

<style lang="scss">
    html {
        background: linear-gradient(198deg, #4DB2E7 0%, #001E78 100%);
    }

    .matchmaker {
        &__progress-bar {
            @apply mt-6;

            @screen md {
                @apply mt-28;
            }
        }

        @media (max-height: 700px) {
            &__portrait-img {
                height: 200px;
            }

            &__aldi-logo {
                height: 40px;
            }

            &__exp-logo {
                height: 70px;
            }

            @screen md {
                &__progress-bar {
                    @apply mt-14;
                }
            }
        }
    }
</style>
