<template>
    <div class="results w-full">
        <div class="results__content flex flex-col items-center">
            <h2 v-if="translations" ref="heading" class="text-center text-white opacity-0">{{ products.length > 1 ? heading : translations.results.headingSingular }}</h2>
            <div class="results__carousel-container w-[256px] md:w-full">
                <div
                    v-if="products && products.length > 0"
                    ref="container"
                    :class="{'md:justify-center': products.length < 3}"
                    class="results__product-container keen-slider mt-4"
                >
                    <div
                        v-for="(product, index) in products"
                        ref="slides"
                        :key="`product-${index}`"
                        :class="[`number-slide${index}`, {'opacity-50 md:opacity-100': index !== currentProductIndex}]"
                        class="results__product product min-w-[256px] max-w-[256px] md:min-w-0 md:max-w-full keen-slider__slide"
                    >
                        <div v-if="product" ref="productContent" class="product__content overflow-hidden opacity-0">
                            <div ref="productFull">
                                <div ref="productPreview" class="product__preview">
                                    <div class="product__image relative flex justify-center items-center bg-white rounded-2xl p-3 h-[254px]">
                                        <a v-if="product.link && product.image" :href="product.link" target="_blank">
                                            <img v-if="product.image && product.image.url" :src="product.image.url" :alt="`Image ${product.name}`" />
                                        </a>
                                        <img v-else-if="!product.link && product.image && product.image.url" :src="product.image.url" :alt="`Image ${product.name}`" />

                                        <button class="product__share-button absolute top-3 right-3 w-10 h-10 transition-all duration-500 hover:scale-110" @click="copyLinkToClipboard">
                                            <img src="~@/static/images/icons/share.svg" alt="Partager" />
                                        </button>
                                        <div v-if="translations" class="product__toast absolute left-0 right-0 top-1/2 bg-blue/80 h3 text-white mx-3 py-5 transform -translate-y-1/2 text-center rounded hidden opacity-0">
                                            {{ translations.results.toast }}
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-start mt-4">
                                        <p v-if="product.name" class="body text-white"><strong>{{ product.name }}</strong></p>
                                        <a v-if="product.link" :href="product.link" target="_blank" class="link text-white whitespace-nowrap ml-4">{{ translations.productCard.productLinkLabel }}</a>
                                    </div>
                                    <div class="flex flex-col gap-y-3 mt-4">
                                        <div v-if="product.region" class="flex items-center gap-x-2">
                                            <img src="~@/static/images/icons/pin.svg" alt="Carte pin" />
                                            <span class="body-3 text-white">{{ product.region }}</span>
                                        </div>
                                        <div v-if="product.color && product.color.value" class="flex items-center gap-x-2">
                                            <img src="~@/static/images/icons/glass.svg" alt="Verre de vin" />
                                            <span class="body-3 text-white">{{ product.color.value }}</span>
                                        </div>
                                        <div v-if="product.cepage" class="flex items-center gap-x-2">
                                            <img src="~@/static/images/icons/grapes.svg" alt="Grappe de raisin" />
                                            <span class="body-3 text-white">{{ product.cepage }}</span>
                                        </div>
                                    </div>
                                    <hr class="border-white my-6" />
                                    <div v-if="product.dishes && product.dishes.length > 0">
                                        <p class="body-2 text-white"><strong>{{ translations.productCard.dishesHeading }}</strong></p>
                                        <div class="flex flex-wrap items-start gap-y-2 gap-x-3 mt-4">
                                            <div v-for="(dish, index) in product.dishes" :key="`dish-${index}`" class="w-14 flex flex-col items-center gap-y-1 text-center">
                                                <img v-if="dish.value" :src="require(`@/static/images/type/${dish.value}.svg`)" alt="Plat" class="w-14 h-14" />
                                                <p class="body-4 text-white">{{ dish.label }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="product.occasions && product.occasions.length > 0" class="mt-6">
                                    <p class="body-2 text-white"><strong>{{ translations.productCard.occasionHeading }}</strong></p>
                                    <div class="flex flex-wrap items-center gap-x-3 mt-4">
                                        <div v-for="(occasion, index) in product.occasions" :key="`occasion-${index}`" class="flex flex-col gap-y-1 text-center">
                                            <img v-if="occasion.value" :src="require(`@/static/images/type/${occasion.value}.svg`)" alt="Occasion"  class="w-14 h-14"/>
                                            <p class="body-4 text-white">{{ occasion.label }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="product.power && (product.sugar || product.taste)" class="mt-6">
                                    <p class="body-2 text-white"><strong>{{ translations.productCard.tasteHeading }}</strong></p>
                                    <div class="flex flex-wrap items-start gap-x-3 mt-4">
                                        <div class="flex flex-col justify-center text-center gap-y-1">
                                            <img :src="require(`@/static/images/jauge/${getGlassType(product.power)}.svg`)" alt="Verre"/>
                                            <span class="body-4 text-white">{{ translations.results.power[product.power] }}</span>
                                        </div>
                                        <div v-if="product.color.value !== 'rouge'" class="flex flex-col justify-center text-center gap-y-1">
                                            <img :src="require(`@/static/images/jauge/${getGlassType(product.sugar)}.svg`)" alt="Verre"/>
                                            <span class="body-4 text-white">{{ translations.results.sugar[product.sugar] }}</span>
                                        </div>
                                        <div v-if="product.color.value === 'rouge'" class="flex flex-col justify-center text-center gap-y-1">
                                            <img :src="require(`@/static/images/jauge/${getGlassType(product.taste)}.svg`)" alt="Verre"/>
                                            <span class="body-4 text-white">{{ translations.results.taste[product.taste] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr class="border-white my-6" />
                                <p v-if="product.description" class="body-3 text-white">{{ product.description }}</p>
                                <div class="flex items-center gap-x-3 mt-4">
                                    <img src="~@/static/images/sommelier.png" alt="Photo conseiller" />
                                    <p class="body-3 text-white">
                                        <strong>Dominique Laporte</strong><br/>
                                        sommelier Aldi
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr class="border-white mt-6 mb-4" />
                        <div class="flex justify-center">
                            <button
                                :data-on="translations.common.seeLess"
                                :data-off="translations.common.seeMore"
                                class="product__see-more-button btn-text btn-text--arrow btn-text--arrow-down"
                                @click="toggleProduct($event, null, index)"
                            >
                                {{ translations.common.seeMore }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-center items-center mt-8">
                <button v-if="translations" class="btn btn--M btn--plain-blue btn--restart" @click="restart">{{ translations.common.restart }}</button>
                <div class="w-full flex items-center gap-x-8 mt-6">
                    <a :href="translations.common.storelink" target="_blank" class="btn-large flex-grow"><img src="~@/static/images/icons/store-finder.svg" alt="Store"/>{{ translations.common.findShop }}</a>
                    <a :href="translations.common.grocerylink" target="_blank" class="btn-large flex-grow"><img src="~@/static/images/icons/shopping-list.svg" alt="Liste de course"/>{{ translations.common.groceryList }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import gsap from '@lahautesociete/gsap';
    import 'keen-slider/keen-slider.min.css';
    import KeenSlider from 'keen-slider';

    export default {
        name: 'MatchmakerResults',

        props: {
            products: { type: Array, default: () => [] },
            translations: { type: Object, default: () => {} }
        },

        data: () => ({
            currentProductIndex: 0,
            options: {
                mode: 'snap',
                slides: {
                    perView: 1,
                    spacing: 16
                },
                breakpoints: {
                    '(min-width: 550px)': {
                        slides: {
                            perView: 1,
                            spacing: 48
                        }
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 3,
                            spacing: 64
                        }
                    }
                }
            }
        }),

        computed: {
            heading() {
                return this.translations.results.heading.replace('{n}', this.products.length);
            }
        },

        watch: {
            currentProductIndex() {
                this.$emit('update-current-product-index', this.currentProductIndex);
            }
        },

        mounted() {
            this.$refs.productContent.forEach((product) => {
                gsap.set(product, {
                    height: product.querySelector('.product__preview').clientHeight
                });
            });

            // Copy options
            this.opts = JSON.parse(JSON.stringify(this.options));

            // Add events
            this.opts.initial = this.currentProductIndex;

            // On slide change, update current index
            this.opts.slideChanged = (s) => {
                // If the current slide is opened
                if (document.querySelectorAll('.product__see-more-button')[this.currentProductIndex].classList.contains('opened')) {
                    // Toggle to close it
                    this.toggleProduct(null, document.querySelectorAll('.product__see-more-button')[this.currentProductIndex])
                }

                // Update the current index
                this.currentProductIndex = s.track.details.rel;
            }

            // Init the carousel
            this.carousel = new KeenSlider(this.$refs.container, this.opts);

            this.enter();
        },

        methods: {
            enter() {
                this.tl = gsap.timeline();

                this.tl.fromTo(this.$refs.heading, {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                this.tl.fromTo(document.querySelectorAll('.product__content'), {
                    y: 20,
                    opacity: 0
                }, {
                    y: 0,
                    stagger: 0.2,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            },

            toggleProduct(event, targetButton, index) {
                // Get the button if it's an event trigger or a parameter element
                const button = targetButton ? targetButton : event.target;

                // Update the text of the button
                button.innerHTML = button.classList.contains('opened') ? button.getAttribute('data-off') : button.getAttribute('data-on');

                // Change the class of the button for the arrow
                button.classList.toggle('btn-text--arrow-down');
                button.classList.toggle('btn-text--arrow-up');

                const indexToChange = window.innerWidth < 768 ? this.currentProductIndex : index;

                // Update the height of the product container to reveal/hide content
                gsap.to(this.$refs.productContent[indexToChange], {
                    height: button.classList.contains('opened') ? this.$refs.productPreview[indexToChange].clientHeight : this.$refs.productFull[indexToChange].clientHeight,
                    duration: 0.6,
                    ease: 'power2.out'
                })

                // Toggle the class to confirm the state
                button.classList.toggle('opened');
            },

            getGlassType(value) {
                if (value < 3) {
                    return 'neutral';
                } else if (value > 3) {
                    return 'full';
                } else {
                    return 'empty';
                }
            },

            copyLinkToClipboard() {
                // Copy the link in clipboard
                navigator.clipboard.writeText(this.products[this.currentProductIndex].link);

                // Show toast to say it's copied
                gsap.to(this.$refs.slides[this.currentProductIndex].querySelector('.product__toast'), {
                    display: 'block',
                    opacity: 1,
                    duration: 0.3,
                    ease: 'power2.out'
                });

                gsap.to(this.$refs.slides[this.currentProductIndex].querySelector('.product__toast'), {
                    display: 'none',
                    opacity: 0,
                    duration: 0.3,
                    delay: 2.5,
                    ease: 'power2.out'
                });
            },

            restart() {
                this.$emit('restart');
            }
        }
    }
</script>

<style lang="scss">
    .keen-slider:not([data-keen-slider-disabled]) {
        @apply overflow-visible;
    }
</style>
