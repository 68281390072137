<template>
    <div v-if="translations" class="matchmaker-bonus mx-4">
        <div class="flex flex-col md:flex-row max-w-[450px] md:max-w-[1248px] mx-auto px-4 md:px-18 pt-10 pb-8 md:pt-14 md:pb-5 md:gap-x-16 bg-yellow-light rounded-2xl ">
            <div class="md:w-full">
                <h2 class="text-blue w-8/12 md:w-full" v-html="translations.common.recipeHeading"></h2>
                <a v-if="recipe && recipe.url && recipe.heading" :href="recipe.url" target="_blank" class="relative block w-full h-[232px] md:h-[307px] bg-beige rounded-2xl mt-4 overflow-hidden">
                    <picture>
                        <source media="(min-width: 768px)" srcset="~@/static/images/cook-desktop.png" />
                        <img src="~@/static/images/cook-mobile.png" alt="Recette" class="absolute inset-0 w-full h-full object-cover" />
                    </picture>
                    <div class="overlay absolute inset-0"></div>
                    <span class="block h2 text-white absolute left-4 bottom-8 right-8 md:left-8 md:bottom-14 md:w-8/12">{{ recipe.heading }}</span>
                </a>
            </div>
            <div class="md:w-full mt-8 md:mt-0">
                <h2 class="text-blue w-8/12 md:w-full" v-html="translations.common.topicHeading"></h2>
                <a :href="translations.common.topicCardUrl" target="_blank" class="relative block w-full h-[232px] md:h-[307px] bg-beige rounded-2xl mt-4 p-6 pr-15 overflow-hidden">
                    <picture>
                        <source media="(min-width: 768px)" srcset="~@/static/images/choose-desktop.png" />
                        <img src="~@/static/images/choose-mobile.png" alt="Cuisiner" class="absolute inset-0 w-full h-full object-cover" />
                    </picture>
                    <div class="overlay absolute inset-0"></div>
                    <span class="block h2 text-white absolute left-4 bottom-8 right-8 md:left-8 md:bottom-14 md:right-0 md:w-8/12 ">{{ translations.common.topicCardLabel}}</span>
                </a>
            </div>
        </div>

        <div class="matchmaker__legal-warning flex flex-col justify-center items-center py-4">
            <img src="~@/static/images/icons/abus.png" alt="Attention abus"/>
            <p class="body-4 font-bold text-white ml-1">L'abus d'alcool est dangereux pour la santé</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MatchmakerBonus',

        props: {
            translations: { type: Object, default: () => {} },
            recipe: { type: Object, default: () => {} }
        }
    }
</script>

<style lang="scss" scoped>
    .overlay {
        background: linear-gradient(0deg, rgba(23, 23, 23, 0.80) 0%, rgba(47, 47, 47, 0.00) 89.33%);
    }
</style>
