<template>
    <div ref="container" class="legal-popin hidden">
        <div ref="dimmer" class="legal-popin__dimmer bg-black/40 fixed w-full h-full inset-0"></div>
        <div ref="content" class="legal-popin__content bg-white rounded-2xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-3 py-6 min-w-[295px] max-w-[409px]">
            <div ref="initial">
                <p class="body-2 text-blue text-center">{{ translations.legal.initial }}</p>
                <div class="flex flex-col md:flex-row items-center md:justify-center mt-6 gap-3">
                    <button class="btn btn--plain-blue btn--M whitespace-nowrap" @click="closePopin">{{ translations.legal.btnAccept }}</button>
                    <button class="btn btn--plain-blue btn--M whitespace-nowrap" @click="showMessage">{{ translations.legal.btnRefuse }}</button>
                </div>
            </div>
            <div ref="refuse" class="legal-popin__refuse hidden h-0 opacity-0">
                <p class="body-2 text-blue text-center">{{ translations.legal.refused }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'LegalPopin',

        props: {
            translations: { type: Object, default: () => {} }
        },

        mounted() {
            const legal = localStorage.getItem('legalPassed');

            if (legal !== 'true') {
                gsap.set(this.$refs.container, {
                    display: 'block'
                });
            }
        },

        methods: {
            closePopin() {
                gsap.to([this.$refs.dimmer, this.$refs.content], {
                    opacity: 0,
                    display: 'none',
                    duration: 0.4,
                    ease: 'power3.out'
                });

                localStorage.setItem('legalPassed', 'true');
            },

            showMessage() {
                gsap.set(this.$refs.refuse, {
                    display: 'block'
                });

                gsap.to(this.$refs.initial, {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3,
                    delay: 0.1,
                    ease: 'power3.out'
                });

                gsap.to(this.$refs.content, {
                    height: this.$refs.refuse.querySelector('p').clientHeight + 48,
                    duration: 0.4,
                    delay: 0.3,
                    ease: 'power2.out'
                });


                gsap.to(this.$refs.refuse, {
                    opacity: 1,
                    height: this.$refs.refuse.querySelector('p').clientHeight,
                    duration: 0.3,
                    delay: 0.3,
                    ease: 'power3.out'
                });
            }
        }
    }
</script>
