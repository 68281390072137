<template>
    <div
        :class="{'bottle-slider--reverse': reverse, 'bottle-slider--champagne': bottleType === 'CHAMPAGNE'}"
        class="bottle-slider w-[122px] h-[240px] md:w-[384px] md:h-[211px] flex justify-center items-center"
    >
        <div class="bottle-slider__content relative w-60 h-15 md:w-[384px] md:h-[99px] transform flex-shrink-0 -rotate-90 md:rotate-0">
            <img :src="require(`@/static/images/${bottleType.toLowerCase()}-bottle.svg`)" class="w-full h-full absolute inset-0 pointer-events-none select-none" />
            <div ref="wine" class="bottle-slider__wine bg-pinky-red absolute inset-0 h-full w-[28px] md:w-[35px]"></div>
            <div ref="handle" class="bottle-slider__handle w-3.5 h-3.5 bg-pinky-red rounded-full"></div>
            <div class="bottle-slider__wave-mask absolute inset-0 w-full h-full">
                <div ref="waveContainer" class="bottle-slider__wave absolute top-0 left-0 w-[38px] h-full overflow-hidden select-none">
                    <img ref="wave" src="~@/static/images/wave.svg" class="absolute top-full"/>
                </div>
            </div>
            <div :class="{'-bottom-10': !reverse, '-top-10 md:-bottom-10 md:top-inherit': reverse}" class="bottle-slider__labels absolute left-0 w-full flex justify-between px-2 select-none">
                <p class="bottle-label text-white">{{ labelMin }}</p>
                <p class="bottle-label text-white">{{ labelMax }}</p>
            </div>
            <div ref="gauge" class="bottle-slider__gauge absolute left-[26px] top-0 w-[137px] md:w-[289px] h-full">
                <input
                    v-model="sliderValue"
                    type="range"
                    :min="min"
                    :max="max"
                    :step="1"
                    @input="handleInput"
                    class="bottle-slider__range relative w-full h-full"
                />
                <img src="~@/static/images/segments.svg" class="absolute top-1/2 left-0 transform -translate-y-1/2 pointer-events-none select-none w-full" />
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'BottleSlider',

        props: {
            bottleType: { type: String, default: 'WINE' },
            labelMin: { type: String, default: '' },
            labelMax: { type: String, default: '' },
            reverse: { type: Boolean, default: false },
        },

        data: () => ({
            sliderValue: 0,
            min: 1,
            max: 5,
            baseMovementStart: 0,
            previousValue: 0
        }),

        watch: {
            sliderValue() {
                // Animate the wine
                gsap.to(this.$refs.wine, {
                    width: this.baseMovementStart + (this.$refs.gauge.clientWidth * (this.sliderValue - 1)) / 4
                });

                // Animate the handle
                gsap.to(this.$refs.handle, {
                    x: (this.$refs.gauge.clientWidth * (this.sliderValue - 1)) / 4
                });

                // Animate the wave container
                gsap.to(this.$refs.waveContainer, {
                    x: this.baseMovementStart + (this.$refs.gauge.clientWidth * (this.sliderValue - 1)) / 4
                });

                // If we are not at the max and it's not already animating
                if ((!this.waveTween || this.waveTween && !this.waveTween.isActive()) && this.sliderValue > this.previousValue && window.innerWidth < 768){

                    // Animate the wave itselfs
                    this.waveTween = gsap.fromTo(this.$refs.wave, {
                        y: 0
                    }, {
                        y: - this.$refs.gauge.clientHeight - this.$refs.wave.clientHeight,
                        duration: 2,
                        delay: 0.3,
                        ease: 'power1.out'
                    })
                }

                this.previousValue = this.sliderValue;
            }
        },

        mounted() {
            this.baseMovementStart  = window.innerWidth < 768 ? 28 : 35;
            this.sliderValue = this.modelValue ?? 0;
        },

        methods: {
            handleInput() {
                this.$emit('update:modelValue', parseInt(this.sliderValue));
            }
        }
    }
</script>

<style lang="scss">
    .bottle-slider {
        &__wine, &__wave-mask {
            mask-image: url('@/static/images/wine-bottle.svg');
            mask-size: 240px;

            @screen md {
                mask-size: 384px;
            }
        }

        &--champagne {
            .bottle-slider__wine, .bottle-slider__wave-mask {
                mask-image: url('@/static/images/champagne-bottle.svg');
                mask-size: 240px;

                @screen md {
                    mask-size: 384px;
                }
            }
        }

        &__handle {
            @apply absolute -top-[25px] left-[28px];
            margin-left: -8px;

            &:after {
                content: '';
                @apply w-0.5 h-[79px] bg-pinky-red absolute left-1/2 top-3.5 transform -translate-x-1/2;
            }

            @screen md {
                @apply left-[35px];
            }
        }

        &__range {
            @apply appearance-none bg-transparent;
        }

        &__range::-webkit-slider-thumb {
            @apply appearance-none w-3.5 h-3.5;
            margin-top: -52px;
            opacity: 0;

            @screen md {
                margin-top: -100px;
                padding: 10px;
            }
        }

        &__range::-moz-range-thumb {
            @apply appearance-none w-3.5 h-3.5;
            transform: translateY(-49px);
            opacity: 0;

            @screen md {
                transform: translateY(-69px);
            }
        }

        &--reverse {
            .bottle-slider__handle {
                @apply -bottom-[25px];
                top: inherit;

                &:after {
                    top: inherit;
                    @apply bottom-3.5;
                }

                @screen md {
                    @apply -top-[25px];
                    bottom: inherit;

                    &:after {
                        bottom: inherit;
                        @apply top-3.5;
                    }
                }
            }

            .bottle-slider__range::-webkit-slider-thumb {
                margin-top: 90px;

                @screen md {
                    margin-top: -80px;
                }
            }

            .bottle-slider__range::-moz-range-thumb {
                transform: translateY(49px);

                @screen md {
                    transform: translateY(-69px);
                }
            }
        }
    }
</style>
