<template>
    <div class="progress-bar relative h-[22px] w-full max-w-[205px] md:max-w-[400px] flex justify-center items-center">
        <div class="w-full h-1 bg-white rounded-full"></div>
        <div class="h-1 bg-blue rounded-full absolute top-1/2 left-0 transform -translate-y-1/2" :style="`width: ${position}%`"></div>
        <div class="progress-bar__thumb" :style="`left: ${position}%`">{{ current }}</div>
    </div>
</template>

<script>
    export default {
        name: 'ProgressBar',

        props: {
            max: { type: Number, default: 3 },
            current: { type: Number, default: 1 }
        },

        computed: {
            position() {
                return (this.current === 1) ? '10' :  100 * (this.current - 1) / (this.max - 1);
            }
        }
    }
</script>

<style lang="scss">
    .progress-bar {
        &__thumb {
            @apply font-din font-bold absolute w-[22px] h-[22px] bg-blue rounded-full flex justify-center items-center transform -translate-x-1/2 transition-all duration-300 text-white;
            font-size: 0.75rem;
        }
    }
</style>
